<template>
  <div class="wizard-body mx-auto bg-white shadow-sm">
    <AlreadyTakenPopup
      ref="alreadyTakenPopup"
      :email_taken="email_taken"
      :personnr_taken="personnr_taken"
      :rate_limit="rate_limit"
      @toLoginClicked="toLoginClicked"
    />
    <b-card v-if="created" title="Medlemskonto skapat!" hide-footer class="mb-2 mt-2 ">
      <b-alert variant="success" show
        >Ditt konto har skapats och en aktiveringslänk har skickats till din angivna
        e-postadress</b-alert
      >
    </b-card>

    <b-card v-if="error" title="Ett fel uppstod" hide-footer class="mb-2 mt-2 bg-white">
      <b-alert variant="danger" show>Ditt konto blev inte skapat. Försök igen senare.</b-alert>
    </b-card>

    <!-- End: Member Type-->
    <b-card v-if="!created" title="" class="wizard-body mb-5 pt-5 bg-white" hide-footer>
      <!--begin: Wizard-->

      <b-card v-if="pages === null || pages.length === 0">
        <div class="d-flex justify-content-center mb-3">
          <memlist-spinner />
        </div>
      </b-card>

      <!--begin: Wizard Body-->
      <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="col-xl-12">
          <!--begin: Wizard Form-->
          <form class="form" id="kt_form" @submit.prevent="onSubmit">
            <template v-for="(page, i) in pages">

              <!-- Begin: Personnr -->
              <div :key="i + 2000" v-if="page.type === 'personnr'">
                <PersonnrPage

                  ref="ref_page_personnr"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :page_data.sync="page_data"
                  :page="page"
                  :isRenew="false"
                  @ageUpdated="ageUpdated"
                  @doSparLookup="doSparLookup"
                  @isAlreadyRegistered="personnrIsAlreadyRegistered"
                />
              </div>
              <!-- End: Personnr -->

              <!-- Begin: Details -->
              <div :key="i + 3000" v-else-if="page.type === 'details'">
                <DetailsPage

                  ref="ref_page_details"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  @isAlreadyRegistered="emailIsAlreadyRegistered"
                  :requirements="requirements"
                  :page_data.sync="page_data"
                  :spar="spar"
                  :page="page"
                  :regions="regions"
                />
              </div>

              <!-- End: Details -->

              <!-- Begin: Student -->
              <div :key="i + 4000" v-else-if="page.type === 'student'">
                <StudentPage

                  ref="ref_page_student"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :pageData.sync="page_data"
                  :page="page"
                />
              </div>

              <!-- End: Student -->

              <!-- Begin: Register Family -->
              <div :key="i + 5000" v-else-if="page.type === 'reg_family'">
                <RegisterFamily

                  ref="ref_page_reg_family"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :company_id="company_id"
                  :page_data.sync="page_data"
                  :page="page"
                  :me="me"
                  :regions="regions"
                />
              </div>

              <!-- End: Register Family -->

              <!-- Begin: Properties -->
              <div :key="i + 6000" v-else-if="page.type === 'properties'">
                <PropertyPage

                  ref="ref_page_properties"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :company_id="company_id"
                  :page="page"
                  :page_data.sync="page_data"
                />
              </div>
              <!-- End: Properties -->

              <!-- Begin: Parents -->
              <div

                :key="i + 7000"
                v-else-if="page.type === 'parents'"
              >
                <ParentsPage

                  ref="ref_page_parents"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :age="age"
                  :page_data.sync="page_data"
                  :ageMaxParent="company.registration.age_max_parent"
                  :page="page"
                  :regions="regions"
                />
              </div>
              <!-- End: Parents -->

              <!-- Begin: Shop -->
              <div :key="i + 8000" v-else-if="page.type === 'shop'">
                <ShopMembershipSelect
                  class="mb-4"
                  ref="ref_page_shop"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :member_renew_id="null"
                  :memberships="memberships"
                  :extras="extras"
                  :page="page"
                  :page_data.sync="page_data"
                  :age="age"
                  :company_id="company_id"
                  :company_ids="company_ids"
                  @membershipUpdated="membershipUpdated"
                />
              </div>
              <!-- End: Shop -->

              <!-- Begin: registration V2 -->
              <div class="fade-in-up-enter-to" :key="i + 11000" v-else-if="page.type === 'company_select_v2'">
                <CompanySelectRegistrationV2
                  v-show="renderPage.id === page.id"
                  ref="ref_page_company_select_v2"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :company_id="company_id"
                  :page_data.sync="page_data"
                  :page="page"
                  :region_code="region_code"
                  :commune_code="commune_code"
                />
              </div>
              <!-- End: registration V2 -->

              <!-- Begin: Final confirm step -->
              <div :key="i + 9000" v-else-if="page.type === 'confirm'">
                <ConfirmRegisterPage

                  ref="ref_page_confirm"
                  @wizardPageWasUpdated="wizardPageWasUpdated"
                  :requirements="requirements"
                  :page="page"
                  :regdata="regdata"
                  :company="company"
                  :page_data.sync="page_data"
                  @isAllPolicyChecked="isAllPolicyChecked"
                />
              </div>
              <!-- End: Final confirm step -->
            </template>

            <!--begin: Wizard Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div>
                <button
                  class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  type="submit"
                  :disabled="submitDisabled"
                >
                  {{ this.page_data.data.mustpay ? $t('PAGES.REGISTRATION.PROCEED_TO_PAYMENT') : $t('PAGES.REGISTRATION.CREATE_ACCOUNT') }}
                </button>
              </div>
            </div>
            <!--end: Wizard Actions -->
          </form>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>

.wizard-body {
  max-width: 1000px;
  width: 100%;
}
</style>

<script>
import i18nService from "@/core/services/i18n.service.js";
import dayjs from 'dayjs';

import messageParent from '@/core/services/messageParent';
import axios from 'axios';

import ShopMembershipSelect from '@/view/pages/members/register/registerwizard/ShopMembershipSelect.vue';
import ConfirmRegisterPage from '@/view/pages/members/register/registerwizard/ConfirmRegisterPage.vue';
import PropertyPage from '@/view/pages/members/register/registerwizard/PropertyPage.vue';
import PersonnrPage from '@/view/pages/members/register/registerwizard/PersonnrPage.vue';
import AlreadyTakenPopup from '@/view/pages/members/register/registerwizard/AlreadyTakenPopup.vue';
import ParentsPage from '@/view/pages/members/register/registerwizard/ParentsPage.vue';
import DetailsPage from '@/view/pages/members/register/registerwizard/DetailsPage.vue';
import CompanySelectRegistrationV2 from '@/view/pages/members/register/registerwizard/company_select_v2/CompanySelectRegistrationV2.vue';
import RegisterFamily from './registerwizard/RegisterFamily';
import StudentPage from '@/view/pages/members/register/registerwizard/StudentPage.vue';

import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { isPlugin } from "@/core/services/messageParent";


export default {
  name: 'RegisterWizardSinglePage',
  components: {
    ShopMembershipSelect,
    ConfirmRegisterPage,
    PropertyPage,
    PersonnrPage,
    ParentsPage,
    DetailsPage,
    CompanySelectRegistrationV2,
    RegisterFamily,
    AlreadyTakenPopup,
    StudentPage
  },
  mixins: [ toasts ],
  mounted: function() {
    messageParent(JSON.stringify({ message: 'this is a message from the child iframe' }));

    this.loadData();
  },
  data() {
    return {
      submitDisabled: true,
      rate_limit: false,
      email_taken: false,
      personnr_taken: false,
      isNextButtonOK: false,
      pageValidations: {},
      member_renew_id: null,
      me: null,
      family_page: null,
      getPersonnr: null,
      requirements: {},
      spar: null,
      registration: null,
      form: {},
      memberships: [],
      extras: [],
      company: {},
      regdata: {},
      accept_terms: false,
      age: 0,
      groups: [],
      region_code: '',
      commune_code: '',
      regions: [],
      communes: [],
      regionOptions: [],
      communeOptions: [],
      showNext: false,
      currentPage: 0,
      currentPageType: '',
      wizard: null,
      order: {},
      pages: [],
      ref_page_confirm: {
        settings: {
          terms: []
        }
      },
      page_data: {
        data: {}
      },
      created: false,
      error: false,
      show: '',
      show_list: [],
      personNr: {},
      renderPage: null,
      postForm: { // the data from all pages that is POSTed to server
        area_alloc: {
          company_ids: []
        },
        shop: {
          shop_items: [],
          cart: [],
          total_price: 0,
          total_vat: 0
        },
        details: {
          firstname: null, lastname: null, address: null, co: null, commune_code: null, region_code: null, dob: null, email: null, email2: null, invoice_address: null, invoice_firstname: null, invoice_lastname: null, invoice_post: null, invoice_country: null, invoice_zipcode: null, landline: null, password: null, password2: null, phone: null, post: null, zipcode: null, company_name: null
        },
        personnr: {
          personnr: null,
          skip: false
        },
        properties: [],
        family: [],
        parents: {}
      },
    };
  },
  watch: {
    age(newVal, oldVal) {
      // find min age we allow without parent
      if (newVal > this.registration.age_max_parent) {
        this.pages = this.pages.filter(p => p.type !== 'parents');
      }
    },
    commune_code(newVal, oldVal) {
      if (!newVal) {
        return;
      }
      if (this.region_code in this.regions && newVal in this.regions[this.region_code].communes) {
        this.form.commune = this.regions[this.region_code].communes[newVal];
      }
    },
    region_code(newVal, oldVal) {
      if (!newVal) {
        return;
      }

      if (newVal in this.regions) {
        this.form.region = this.regions[newVal].region;
      }
    },

  },
  computed: {


    pageArray() {
      return Object.values(this.page_data)
        .filter(
          a => a.id !== undefined && a.id !== null && a.index !== undefined && a.index !== null
        )
        .sort((a, b) => a.index - b.index);
    },
    lastPage() {
      if (!this.renderPage || !this.pageArray) return false;
      return this.renderPage.id === this.pageArray[this.pageArray.length - 1].id;
    },

    familyPage() {
      return this.pageArray.find(p => p.type === 'reg_family');
    },
    ref_page_parents() {
      return this.pageArray.find(p => p.type === 'parents');
    },
    ref_page_area_alloc() {
      return this.pageArray.find(p => p.type === 'area_alloc');
    },
    company_ids() {
      if (!this.page_data.data.area_alloc) return [this.company_id];

      return this.page_data.data.area_alloc.company_ids;
    }
  },
  methods: {
    emailIsAlreadyRegistered(email) {
      this.showAlreadyRegistered(false, true);
    },
    personnrIsAlreadyRegistered(personnr) {
      this.showAlreadyRegistered(true, false);
    },
    calcSubmitButtonOK() {
      for (const pageType in this.pageValidations) {
        if (!this.pageValidations[pageType].valid) {
          this.submitDisabled = true;
          return;
        }
      }

      this.submitDisabled = false;

    },
    setPageData(pageType, data) {
      const ref_name = `ref_page_${pageType}`;
      const ref = this.$refs[ref_name][0];

      if (!ref) {
        ref.setData(data);
      }

    },

    getPageData(pageType) {

      const ref_name = `ref_page_${pageType}`;
      const ref = this.$refs[ref_name][0];

      if (!ref) {
        console.error('current page has undefined ref', pageType);

        return null;
      }

      return ref.getData();
    },
    getAgeFromDob(dob) {
      const personnr = formatPersonnr(dob);

      if (personnr.success) {
        return dayjs().diff(
          personnr.year + '-' + personnr.month + '-' + personnr.day,
          'years'
        );
      }

      return 18;
    },
    onAreaAllocCompanyIdsSet(company_ids) {
      // when company_ids has been changed from the area_alloc page
      if (this.$refs['ref_page_shop']) {
        this.$refs['ref_page_shop'][0].load_items_manually(company_ids);
      }

    },
    wizardPageWasUpdated(pageType, data, isNextButtonOK) {

      const pd = pageType === 'details' ? data : this.getPageData(pageType);

      if (pd === null || pd === undefined) {
        console.error('wizardPageWasUpdated pd is null', pageType, pd);
        return;
      }

      this.pageValidations[pageType].valid = isNextButtonOK;

      this.calcSubmitButtonOK();

      switch (pageType) {
        case 'student': {
          this.postForm.student = pd;

          this.setPageData('details', pd.member);

          break;
        }
        case 'shop': {
          this.postForm.shop = pd;

          break;
        }
        case 'area_alloc': {
          this.postForm.area_alloc = pd;

          break;
        }
        case 'details': {

          for (const k in pd) {
            if (k && pd[k] !== null && pd[k] !== undefined && pd[k] !== '') {
              this.postForm.details[k] = pd[k];
            }
          }


          if (pd.dob !== null && pd.dob !== undefined && pd.dob !== '') {
            const age = this.getAgeFromDob(pd.dob);
            this.ageUpdated(age);
          }

          if (pd.commune_code) {
            if (this.$refs['ref_page_area_alloc']) {
              this.$refs['ref_page_area_alloc'][0].setRegionCodeAndCommuneCode(pd.region_code, pd.commune_code, pd.dob);
            }
          }

          break;
        }
        case 'personnr': {
          this.postForm.personnr = pd;

          if (pd.skip) {
            this.isNextButtonOK = true;
          }
          else {
            this.isNextButtonOK = isNextButtonOK;
          }

          break;
        }
        case 'reg_family': {
          this.postForm.family = pd;

          break;
        }
        case 'parents': {
          this.postForm.parents = pd;

          break;
        }

        case 'properties': {
          this.postForm.properties = pd;

          break;
        }

      }

    },
    onSubmit() {
      this.createAccount();
    },

    showAlreadyRegistered(personnr_taken, email_taken) {
      this.personnr_taken = personnr_taken;
      this.email_taken = email_taken;

      this.$refs['alreadyTakenPopup'].show();
    },

    isAllPolicyChecked(isChecked) {
      this.accept_terms = isChecked;
    },
    doSparLookup(personnr) {

      axios
        .post(`/member/spar_personnr`, { personnr })
        .then(res => {
          if (res.status === 200) {
            this.spar = res.data;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.OK'), 'Kunde inte göra SPAR uppslag');
        });
    },
    ageUpdated(age) {
      if (!this.ref_page_parents) return;

      const skip = age >= this.registration.age_max_parent;

      this.page_data[this.ref_page_parents.id] = {
        ...this.page_data[this.ref_page_parents.id],
        skip
      };
      // Vue3 compatability for Vue.set
      this.page_data = { ...this.page_data };
    },
    membershipUpdated(memberships) {
      if (!this.familyPage) {
        console.error('skip because family page is false');
        return;
      }

      const skip = memberships.find(m => m.is_family) ? false : true;

      this.page_data[this.familyPage.id] = {
        ...this.page_data[this.familyPage.id],
        skip
      };
      // Vue3 compatability for Vue.set
      this.page_data = { ...this.page_data };

    },
    toLoginClicked() {
      location.href = '/login/' + this.company_id;
    },
    async loadData() {
      this.company_id = this.$route.params.id;

      this.loadRegistration(this.company_id);
      this.loadRegions();
    },
    loadRegistration(company_id) {
      axios
        .get(`/company/public/${company_id}`)
        .then(res => {
          this.company = res.data;
          this.memberships = [];
          this.extras = [];

          this.requirements = {
            MEMBER_REQ_PERSONNR: false,
            MEMBER_REQ_SSN: false,
            MEMBER_REQ_AGE: false,
            MEMBER_REQ_SEX: false,
            MEMBER_REQ_ADDRESS: false,
            MEMBER_REQ_FULLNAME: false,
            MEMBER_REQ_EMAIL: false,
            MEMBER_REQ_AVD: false,
            MEMBER_REQ_PHONE: false,
            MEMBER_REQ_EMAILPHONE: false,
            MEMBER_REQ_ORG_NUMBER: false,
            MEMBER_REQ_CNAME: false,
          };

          for (const r of res.data.requirements) {
            this.requirements[r.name] = true;
          }

          this.company.shop_items.filter(item => {
            if (item.is_member_fee && Boolean(item.is_active))
              this.memberships.push(item);
            if (!item.is_member_fee && Boolean(item.is_active)) this.extras.push(item);
          });

          this.accept_terms = false;

          this.registration = res.data.registration;

          if (this.registration.lang) {
            i18nService.setActiveLanguage(this.registration.lang);
          }

          this.pages = res.data.pages;

          const final_pages = [];

          for (const p of this.pages) {
            if (p.settings === null || p.settings === undefined) {
              final_pages.push(p);
              continue;
            }

            if (p.settings.is_register === null || p.settings.is_register === undefined) {
              final_pages.push(p);
              continue;
            }

            if (p.settings.is_register) {
              final_pages.push(p);
            }
          }

          this.pages = final_pages;

          this.page_data = this.pages.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {});

          this.page_data.data = {};
          // Vue3 compatability for Vue.set
          this.page_data = { ...this.page_data };
          this.renderPage = this.pages[0];

          this.currentPageType = this.renderPage.type;

          for (const p of this.pages) {
            this.pageValidations[p.type] = { valid: false };
          }

          setTimeout(function() {
            messageParent(JSON.stringify({ height: document.body.scrollHeight }));
          }, 300);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta registreringswizard');
        });
    },
    createAccount() {
      const loader = this.$loading.show();

      let data = this.validateData(this.postForm);

      axios
        .post('/member/public', data)
        .then(res => {
          loader && loader.hide();

          if (res.status === 201) {
            messageParent(
              JSON.stringify({
                register: true,
                member_id: res.data.member_id,
                token: res.data.token
              })
            );

            const order = res.data.order;

            if (order !== null && order.total_sek > 0) {
              if (order.stripe_client_secret) {
                this.$router.push(`/stripe-payment/${res.data.member_id}?client_secret=${order.stripe_client_secret}&type=register&token=${order.token}`);
              }
              else {
                this.$router.push(`/payment/${order.shop_order_id}/${order.dintero_id}/${order.token}`);
              }

            } else {
              if (this.company.company_settings.post_register_url) {
                let url = this.company.company_settings.post_register_url;

                // add http:// if not present

                if (!url.match(/^https?:\/\//)) {
                  url = 'http://' + url;
                }

                messageParent(JSON.stringify({ redirect: url }));

                if (!isPlugin()) {
                  window.location.href = url;
                }
              } else {
                this.created = true;
              }
            }
          } else if (res.status === 409) {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'E-mail finns redan registrerad');
          } else {
            this.error = true;
            messageParent(JSON.stringify({ register: false }));
          }
        })
        .catch(err => {
          loader && loader.hide();

          messageParent(JSON.stringify({ register: false }));

          this.error = true;
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa medlemskonto');
        });
    },
    validateData(data) {

      // TODO: For now, make sure that everything that the backend requires exists on the object
      // Later, we should make sure that the data is valid and aligned with the backend
      if (!data.area_alloc || !data.area_alloc.company_ids || data.area_alloc.company_ids.length === 0) {
        data.area_alloc = { company_ids: [this.company_id] };
      }

      return data;
    },
    loadRegions() {
      axios
        .get('https://zip.memlist.se/api/v1/regions_communes')
        .then(res => {
          this.regions = res.data.regions;
          this.regionOptions = [];

          for (var k in this.regions) {
            this.regionOptions.push({ value: k, text: this.regions[k].region });
          }

          this.regionOptions.sort(function(a, b) {
            if (a.text < b.text) {
              return -1;
            }
            if (a.text > b.text) {
              return 1;
            }
            return 0;
          });
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta regioner');
        });
    },
  }
};
</script>
